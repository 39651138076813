<template>
  <div class="pay-message-card">
    <div class="title">
      <span class="icon"></span><span class="font">账单信息</span>
    </div>
    <van-cell-group class="cell">
      <van-checkbox-group
        v-model="result"
        v-for="(item, index) in content"
        :key="index"
      >
        <van-field
          clickable
          input-align="right"
          error-message-align="right"
          :disabled="checkIsOk[index] || checkSplit(item)"
          @focus="selectedItem(index)"
          v-model="item.price"
          type="number"
          :name="item.subjectName"
          placeholder="请输入金额"
          :class="{ input_line: !(checkIsOk[index] || checkSplit(item)) }"
          :error-message="
            checkAmount[index]
              ? '输入的金额不在允许范围'
              : item.orderStatus === '部分支付' ||
                item.orderStatus === '支付完成'
              ? '已支付' + item.actuallyPaidAmount + '元'
              : item.orderStatus == '部分退款' || item.orderStatus == '已退款'
              ? '已退款'
              : ''
          "
        >
          <template #label class="label">
            <van-checkbox
              :name="index"
              ref="checkboxes"
              :disabled="checkIsOk[index] || checkRequired(item.id)"
              >{{ item.subjectName }}</van-checkbox
            >
          </template>
          <template #button>
            <span>¥</span>
          </template>
        </van-field>
      </van-checkbox-group>
      <!-- 缴费合计 -->
      <div class="sum-amount">
        <span>合计：{{ sumAmount }}</span>
      </div>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: 'PayMessageCard',
  props: {
    content: Array,
    requiredPayList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      result: [] //选中的选项
    }
  },
  computed: {
    checkAmount() {
      //检查金额是否在合理范围
      let results = []
      for (const iterator of this.content) {
        if (
          this.$store.state.split &&
          parseFloat(iterator.price) > 0 &&
          parseFloat(iterator.price) <= parseFloat(iterator.shouldPayAmount) //拆单，输入金额在0到应付之间
        ) {
          //在范围
          //console.log(1)
          results.push(false)
          continue
        }
        if (
          !this.$store.state.split &&
          parseFloat(iterator.price) == parseFloat(iterator.shouldPayAmount)
        ) {
          //不拆单金额等于应付金额
          // console.log(2)
          results.push(false)
          continue
        }
        if (parseFloat(iterator.shouldPayAmount) == 0) {
          //自定义金额
          // console.log(3)
          results.push(false)
          continue
        }
        results.push(true)
      }
      return results
    },
    checkSplit() {
      return (item) => {
        if (item.orderStatus === '支付完成') {
          //支付完成禁用
          return true
        } else if (parseFloat(item.shouldPayAmount) == 0) {
          //自定义启用
          return false
        } else return !this.$store.state.split //是否拆单
      }
    },

    sumAmount() {
      //计算缴费总计
      let sumNum = 0
      for (let i = 0; i < this.content.length; i++) {
        if (this.result.indexOf(i) != -1)
          sumNum += Number(this.content[i].price)
      }
      return sumNum.toFixed(2) + ' ¥'
    },
    checkIsOk() {
      //检查多选框状态
      let state = []
      for (let i = 0; i < this.content.length; i++) {
        this.content[i].flag = ''
        if (
          this.content[i].orderStatus === '支付完成' ||
          this.content[i].orderStatus === '部分退款' ||
          this.content[i].orderStatus === '已退款'
        ) {
          state[i] = true
          continue
        } else {
          state[i] = false
        }
        if (this.result.length > 0) {
          state[i] = this.content[this.result[0]].cusid != this.content[i].cusid //互斥
        }
      }
      for (let i = 0; i < this.result.length; i++) {
        this.content[this.result[i]].flag = '1'
      }
      return state
    }
  },
  methods: {
    checkRequired(id) {
      for (const idElement of this.requiredPayList) {
        const ids = this.content.map((item) => item.id)
        const a = ids.indexOf(idElement)
        if (a < 0) continue // 解决必缴费用为负值的问题
        const index = this.result.indexOf(a)
        if (index < 0) {
          this.result.push(a)
        }
      }
      return this.requiredPayList.indexOf(id) > -1
    },
    selectedItem(index) {
      //自动勾选
      if (this.result.indexOf(index) < 0) {
        this.result.push(index)
      }
    }
  }
}
</script>

<style scoped lang="less">
.pay-message-card {
  background-color: white;
  padding: 5px;
  box-shadow: 1px 1px 3px #bbbbbb;

  .title {
    padding: 5px;
    margin-bottom: 10px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    .icon {
      padding: 2px 0;
      width: 3px;
      height: 16px;
      display: block;
      background-color: #3975f1;
      border-radius: 2px;
    }
    .font {
      font-size: 14px;
      padding: 0 5px;
    }
  }
  .cell {
    // 账单
    /deep/.van-checkbox__label {
      font-size: 12px;
    }
    /deep/.van-field__label {
      width: calc(12px * 15);
    }
    /deep/.van-cell {
      padding: 5px 14px;
    }
    .input_line {
      /deep/.van-field__control {
        border-bottom: 1px solid #bbbbbb;
        color: #bbbbbb;
        width: 70px;
        background-color: white;
        border-radius: 0;
      }
      /deep/.van-field__body {
        justify-content: flex-end;
      }
      /deep/.van-cell__value {
        //left: 115px;
      }
    }
  }
  /deep/[class*='van-hairline']::after {
    border: none;
  }
  .sum-amount {
    border-top: 1px solid #e5e5e5;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    margin: 10px;
    margin-bottom: 0;
    padding: 5px;
    padding-bottom: 0;
    color: #ff5151;
  }
}
</style>
