<template>
  <div class="base-message-card">
    <div class="title">
      <span class="icon"></span><span class="font">基本信息</span>
    </div>
    <!--   基础信息   -->
    <div class="base-message">
      <ul>
        <li v-for="item in content" :key="item.text" class="base-message-item">
          <span>{{ item.label }}</span>
          <span class="font">{{ item.value }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseMessageCard',
  props: {
    content: Array
  },
  mounted() {}
}
</script>
<style scoped lang="less">
.base-message-card {
  background-color: white;
  box-shadow: 1px 1px 3px #bbbbbb;

  .title {
    padding: 5px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    .icon {
      padding: 2px 0;
      width: 3px;
      height: 16px;
      display: block;
      background-color: #3975f1;
      border-radius: 2px;
    }
    .font {
      font-size: 14px;
      padding: 0 5px;
    }
  }
  .base-message {
    font-size: 12px;
    ul {
      padding: 10px;
      .base-message-item {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        .font {
          color: #999999;
        }
      }
    }
  }
}
</style>
