<template>
  <div>
    <!-- 标题，缴费信息 -->
    <head-text :title="this.$store.state.title">
      <base-message-card
        slot="baseMessageCard"
        :content="baseMessages"
      ></base-message-card>
    </head-text>
    <!-- 缴费信息 -->
    <pay-message-card
      class="pay-message"
      :content="payMessage"
      :requiredPayList="requiredPayList"
      ref="pay"
    ></pay-message-card>
    <!--  提交按钮  -->
    <div class="submit-button">
      <van-button
        round
        loading-text="提交中..."
        @click="onSubmit()"
        type="info"
        :disabled="changeState === 0"
        :loading="subFlag"
        native-type="submit"
        >立即支付
      </van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import request from '@/api/request'
import HeadText from '@/components/common/default/HeadText'
import PayMessageCard from '@/components/orderPage/default/PayMessageCard'
import BaseMessageCard from '@/components/orderPage/default/BaseMessageCard'

export default {
  name: 'OrderPageDefault',
  components: {
    PayMessageCard,
    BaseMessageCard,
    HeadText
  },
  data() {
    return {
      showFlag: false, //true显示体，false显示暂无信息
      subFlag: false, //提交按钮状态
      baseMessages: [], //baseMessages, 基本信息配置
      payMessage: [], //payMessage, 缴费信息配置
      combination: 0, //总金额
      loading: true, //loading
      requiredPayList: [] // 必缴信息
    }
  },
  computed: {
    changeState() {
      //监听多选框状态改变事件，改变提交按键状态。
      this.combination = 0
      for (let i = 0; i < this.payMessage.length; i++) {
        if (
          this.payMessage[i].flag === '1' &&
          this.payMessage[i].price !== '' &&
          parseFloat(this.payMessage[i].price) > 0
        ) {
          this.combination += Number(this.payMessage[i].price)
        }
      }
      return this.combination
    }
  },
  methods: {
    onSubmit() {
      //表单提交事件
      const form = {
        subjectList: [],
        remark: ''
      }
      let a = this.judgeStatus(form)
      if (a[0] !== 0) {
        this.startPay(...a) //支付发起
      }
    },

    startPay(form, payFlag) {
      //支付验证和发起支付
      if (payFlag) {
        Toast.fail('缴费金额不在允许的范围')
      } else {
        this.subFlag = true
        // 有code则提交
        if (this.$route.query.code) {
          form.wxcode = this.$route.query.code
        }
        // console.log(form) //提交的订单报文
        request
          .post('/payment/wx/school/order/pay', form)
          .then((res) => {
            this.subFlag = false
            window.location.href = res.content.url + '?' + res.content.body
          })
          .catch((err) => {
            this.subFlag = false
            console.error(err)
            Toast.fail(err.message)
          })
      }
    },

    judgeStatus(form) {
      //提交时判断输入的状态，生成表单
      let payFlag = false
      for (let i of this.$refs.pay.result) {
        //整合支付信息
        if (
          this.payMessage[i].price !== '' &&
          parseFloat(this.payMessage[i].price) > 0
        ) {
          //只提交选中且非空的订单
          if (this.$refs.pay.checkAmount[i]) {
            //判断是否输入值是否在范围
            payFlag = true
            break
          }
          if (
            this.payMessage[i].price.indexOf('.') + 3 >=
              this.payMessage[i].price.length ||
            this.payMessage[i].price.indexOf('.') < 0
          ) {
            //判断是否是两位小数
            form.subjectList.push({
              orderId: this.payMessage[i].orderId,
              subjectId: this.payMessage[i].id,
              shouldPayAmount: this.payMessage[i].price
            })
          } else {
            Toast.fail('金额不能出现两位以上的小数！')
            form = 0
            break
          }
        } else {
          Toast.fail('金额不能为0或空！')
          form = 0
          break
        }
      }

      return [form, payFlag]
    }
  },
  mounted() {
    if (this.$store.state.orderMessages.length > 0) {
      this.showFlag = true
      for (const argument of this.$store.state.orderMessage.baseMessage) {
        this.baseMessages.push({
          label: argument.value,
          value: argument.display
        })
      }
      this.payMessage = this.$store.state.orderMessage.payMessage
      const flag = new Set(this.payMessage.map((item) => item.cusid)).size > 1
      this.requiredPayList = flag ? [] : this.$store.state.style.requiredPayList
    }
  }
}
</script>

<style scoped lang="less">
.pay-message {
  margin: 10px;
  border-radius: 5px;
}
.submit-button {
  margin: 61px auto;
  width: 265px;
  height: 34px;
  font-size: 13px;
  .van-button--round {
    width: 265px;
    height: 34px;
    border-radius: 17px;
  }
}
</style>
